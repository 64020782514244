import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

import SEO from '../components/SEO'
import Layout from '../components/Layout'
import SubPageHero from '../components/Heroes/SubPageHero'
import BasicImageBlock from '../components/ContentBlocks/BasicImageBlock'
import BodyContentBlock from '../components/ContentBlocks/BodyContentBlock'
import EmbeddedForm from '../components/ContentBlocks/EmbeddedForm'
import * as Styled from '../components/styles/ResourceLibraryItem.styles'
import { paginationChange } from '../util/helper'
import { linkResolver } from '../util/linkResolver'
import { BottomLineGoogleAdContainer } from '../components/styles/GoogleAdsCommon.styles'
import AdSlot from '../components/AdSlot'
import AdHead from '../components/AdHead'

export const Head = ({ pageContext }) => {
  return (
    <AdHead adsData={pageContext.adsData} targets={pageContext.pageTargets} />
  )
}

const ResourceLibraryItemFormPage = ({ data }) => {
  const doc = data.prismicResourceLibraryItem.data
  if (!doc) {
    return null
  }

  const settingsNode = data.prismicGeneralSiteSettings.data
  const bottomLineAdSlot =
    settingsNode.subpages_google_ad_tag?.document?.data.ad_slot_name

  const heroText = doc.page_title ? doc.page_title : ''
  const hasImage = doc.image && doc.image.url
  const hasBodyContent = doc.body_copy && doc.body_copy.richText
  const hasForm = doc.form_embed_code && doc.form_embed_code.text

  const formData = {
    id: doc.id,
    primary: {
      form_title: null,
      form_body: doc.form_body,
      form_embed_code: doc.form_embed_code,
    },
  }

  const twitterImage = doc.twitter_image?.url ? doc.twitter_image : null
  const facebookImage = doc.facebook_image?.url ? doc.facebook_image : null
  const seoTitle = doc.meta_title?.text
    ? doc.meta_title.text
    : doc.page_title?.text
    ? doc.page_title.text
    : null
  const seo_embed_code = doc.seo_embed_code?.text ?? null
  const meta_description = doc.meta_description
  const meta_keywords = doc.meta_keywords

  // TODO : SEO
  return (
    <>
      <SEO
        seo={{
          description: meta_description,
          keywords: meta_keywords,
          title: seoTitle,
          twitter_image: twitterImage,
          facebook_image: facebookImage,
          seo_embed_code: seo_embed_code,
        }}
      />
      <Layout>
        <SubPageHero heroText={heroText} />
        <Styled.SliceContainer>
          {hasImage && (
            <BasicImageBlock data={doc} bottomPaddingAdjustment={'2.5%'} />
          )}
        </Styled.SliceContainer>
        {hasBodyContent && <BodyContentBlock data={doc} />}
        {hasForm && <EmbeddedForm data={formData} formType={'code_embed'} />}
        {bottomLineAdSlot && (
          <BottomLineGoogleAdContainer>
            <AdSlot divId={`div-${bottomLineAdSlot}`} />
          </BottomLineGoogleAdContainer>
        )}
      </Layout>
    </>
  )
}

export const query = graphql`
  query ($id: String) {
    prismicGeneralSiteSettings {
      data {
        subpages_google_ad_tag {
          document {
            ... on PrismicGoogleAdTag {
              id
              data {
                ad_slot_name
              }
            }
          }
        }
      }
    }
    prismicResourceLibraryItem(id: { eq: $id }) {
      uid
      id
      data {
        meta_title {
          text
        }
        meta_description
        meta_keywords
        seo_embed_code {
          text
        }
        facebook_image {
          url
        }
        twitter_image {
          url
        }
        form_body {
          richText
        }
        form_embed_code {
          text
        }
        page_title {
          richText
          text
        }
        body_copy {
          richText
          text
        }
        image {
          thumbnails {
            mobile {
              url
            }
          }
          url
        }
      }
    }
  }
`

export default ResourceLibraryItemFormPage
