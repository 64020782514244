import styled from 'styled-components'
import { DEVICE, DISPLAY_WIDTH } from '../../util/constants'
import { GreyFill } from './ProductDirectory.styles'
import { RegionContainer } from '../styles/Utility.styles'
import LinkWrapper from '../ContentBlocks/LinkWrapper'

export const SliceContainer = styled(RegionContainer)`
  figure {
    grid-column: 1 / 12;
  }
`
